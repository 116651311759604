<template>
    <div class="special-furniture">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Muebles Especiales',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Muebles Especiales, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'gondolas, Muebles Especiales, gondolas bucaramanga, Muebles Especiales bucaramanga, industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Muebles Especiales'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Muebles Especiales en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Muebles Especiales'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Muebles Especiales'},
			{itemprop: 'description', content: 'Muebles Especiales en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Muebles Especiales',
            data_products: {
                title_page: 'Muebles especiales',
                types: [
                    { text: 'Bins de Ofertas', value: 'bins-de-ofertas' },
                    { text: 'Canastilla para Liquidación', value: 'canastilla-para-liquidacion' },
                ],
                products: [
                  {
                        name: 'Bins de Ofertas',
                        description: `
                            <p>
                                Estructuras diseñadas para contenedor de productos en ofertas. Con gran capacidad de fondo y 
                                distribuciones de rejillas metálicas, proporcionándole ventilación al producto. 
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Medidas: 100X120X101 cm.</li>
                                <li class="mb-2">Contenedor: Plegable.</li>
                                <li class="mb-2">Altura plegable de 36 cm.</li>
                            </ul> 
                        `,
                        // url_image: 'mobiliario-de-supermercado/muebles-especiales/productos/bins-de-oferta/bins_1.jpg',
                        no_image: true,
                        slides: [
                            // 'mobiliario-de-supermercado/muebles-especiales/productos/bins-de-oferta/bins_1.jpg'
                        ],
                        price: '',
                        tags: ['bins-de-ofertas']
                    },
                    {
                        name: 'Canastilla para Liquidación',
                        description: `
                            <p>
                                Este mueble es un contenedor de productos con una parrilla alargada y separador 
                                en rejilla para mayor  distribución. Tiene dos presentaciones, una en ruedas y 
                                otra con base estática para supermercado.    
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Capacidad de 60 kg de Almacenamiento.</li>
                                <li class="mb-2">Bases plegables y modulares.</li>
                                <li class="mb-2">Soporte con rejilla.</li>
                            </ul> 
                        `,
                        // url_image: 'mobiliario-de-supermercado/muebles-especiales/productos/canastilla/canastilla_1.jpg',
                        no_image: true,
                        slides: [
                            // 'mobiliario-de-supermercado/muebles-especiales/productos/canastilla/canastilla_1.jpg'
                        ],
                        price: '',
                        tags: ['canastilla-para-liquidacion']
                    }
                ]
            }
        }
    },
    computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            // alert(this.url_tag)
            if (typeof this.url_tag != 'undefined') {
                this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
            } else {
                this.page_title = 'Muebles Especiales';
            }
        }
    }
}
</script>